import React, {useEffect, useState} from 'react';
import { navigate } from "@reach/router"
import Layout from "../components/shared/layout";
import Parallax from '../components/shared/Backgrounds/Parallax';
import SEO from '../components/SEO/SEOPage';

const Redirect301Template = ({pageContext }) => {
  const [redirectUrl, setRedirectUrl] = useState(pageContext.redirectUrl);
  useEffect(() => {
    console.log(redirectUrl);
    navigate(redirectUrl);
  }, [redirectUrl]);
  return (
    <Layout>
      <SEO title='Activating...'/>
      <Parallax />
      <h1 className="a-center">301 เปลี่ยนเส้นทาง</h1>
    </Layout>
  );
};

export default Redirect301Template;